<template>
  <div>
    <el-row class="page-header panel">
      <el-col :span="12">
        <h3 class="title">
          <router-link to="/tpr-config">
            <i class="el-icon-back mr10" />
          </router-link>
          {{
            this.edit ? "TPR хязгаарлалтыг засах" : "TPR хязгаарлалтыг нэмэх"
          }}
        </h3>
      </el-col>
      <el-col :span="12">
        <div class="action-section">
          <el-button
            size="mini"
            v-if="this.edit"
            type="success"
            @click="updateTpr(tprData)"
          >
            Хадгалах</el-button
          >
          <el-button
            v-else
            size="mini"
            type="primary"
            @click="createTpr(tprData)"
          >
            Үүсгэх</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-form :model="tprData" ref="tprValidate" :rules="rules" size="small">
      <el-row class="panel">
        <el-col :span="10">
          <el-form-item
            label="Hexagon нэр: "
            label-width="120px"
            prop="hexa_name"
          >
            <el-select v-model="tprData.hexa_name" @change="setHexagonValue">
              <el-option
                v-for="(hexaName, indexHexa) in optionsHexa"
                :key="indexHexa"
                :label="'H' + hexaName.label"
                :value="hexaName.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Ажиллах өдөр: "
            label-width="120px"
            prop="weeking_days"
          >
            <el-select
              v-model="tprData.weeking_days"
              multiple
              placeholder="Өдөр сонгох"
            >
              <el-option
                v-for="(day, indexDay) in optionsDay"
                :key="indexDay"
                :label="day.label"
                :value="day.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Төлөв: " label-width="120px">
            <el-switch
              style="display: block"
              v-model="tprData.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Идэвхтэй"
              inactive-text="Идэвхгүй"
              :active-value="true"
              :inactive-value="false"
            >
            </el-switch>
          </el-form-item>
          <el-form-item
            label="Эхлэх цаг: "
            label-width="120px"
            prop="start_time"
          >
            <el-time-picker
              value-format="HH:mm:ss"
              v-model="tprData.start_time"
              placeholder="Хугацаа сонгох"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item
            label="Дуусах цаг: "
            label-width="120px"
            prop="end_time"
          >
            <el-time-picker
              value-format="HH:mm:ss"
              v-model="tprData.end_time"
              placeholder="Хугацаа сонгох"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item
            label="Эхлэх өдөр: "
            label-width="120px"
            prop="start_date"
          >
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="tprData.start_date"
              type="datetime"
              placeholder="Эхлэх өдөр"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="Дуусах өдөр: "
            label-width="120px"
            prop="end_date"
          >
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="tprData.end_date"
              type="datetime"
              placeholder="Дуусах өдөр"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="TPR хязгаар: "
            label-width="120px"
            prop="tpr_limit"
            class="mr10"
          >
            <el-input v-model="tprData.tpr_limit" placeholder="2"></el-input>
          </el-form-item>
          <el-form-item
            label="Тайлбар: "
            label-width="120px"
            prop="desc"
            class="mr10"
          >
            <el-input
              v-model="tprData.desc"
              type="textarea"
              placeholder="Тайлбар оруулах...."
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item>
            H3: {{ hexagonResourceSize }}
            <gmap-map
              ref="map"
              :center="center"
              :zoom="zoomSize"
              :options="mapOptions"
              :draggable="true"
              style="width:90vh;  height: 50vh;"
            >
              <gmap-marker
                :position="position"
                :clickable="true"
                :draggable="true"
              />
              <gmap-polygon
                v-for="(item, index) in getDefaultHexagonList()"
                :key="index"
                :paths="item.points"
                :options="{
                  fillColor: hexagon_zone.allowed_list.includes(item.label)
                    ? '#000000'
                    : '#FFFFFF',
                  strokeOpacity: 0.2,
                  fillOpacity: getPolygonFillOpacity('allowed_list', item.label)
                    ? 0.5
                    : 0.1
                }"
                :editable="item.editable"
              >
              </gmap-polygon>
            </gmap-map>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="0" class="panel mt10" v-if="this.edit">
      <h4 class="text-center">
        {{ "H" + this.tprData.hexa_name }} TPR тохиргооны түүх
      </h4>
      <el-table size="small" :data="tprDataHistory">
        <el-table-column label="Лог нэр" prop="log_name"></el-table-column>
        <el-table-column label="ID" prop="title"></el-table-column>
        <el-table-column label="Төрөл" prop="type"></el-table-column>
        <el-table-column label="Хэрэглэгч" prop="user_name"></el-table-column>
        <el-table-column label="Хугацаа" prop="created_at"></el-table-column>
        <el-table-column label="Тайлбар" prop="desc"></el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="onClickHistory(scope.row)"
              >Дэлгэрэнгүй</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-dialog
      title="Түүх дэлгэрэнгүй харах"
      center
      v-if="historyDetail"
      :visible.sync="visibleDialoghistory"
      :width="historyDetail.type === 'create' ? '30%' : '60%'"
    >
      <el-row :gutter="20">
        <el-col :span="historyDetail.type === 'create' ? 24 : 12">
          <el-row :gutter="20">
            <el-card class="box-card">
              <el-form :model="historyDetail" :disabled="true">
                <el-row
                  ><span
                    ><strong>Хуучин</strong> -
                    {{ historyDetail.new.created_at }}</span
                  ><el-tag
                    size="mini"
                    type="primary"
                    class="ml10"
                    v-if="historyDetail.new.status"
                    >Идэвхтэй</el-tag
                  >
                  <el-tag size="mini" type="info" class="ml10" v-else
                    >Идэвхгүй</el-tag
                  >
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Hexagon Нэр">
                      <el-input
                        v-model="historyDetail.new.hexa_name"
                      /> </el-form-item
                  ></el-col>
                  <el-col :span="12">
                    <el-form-item label="Үүссэн өдөр">
                      <el-input
                        v-model="historyDetail.new.created_at"
                      /> </el-form-item
                  ></el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Эхлэх өдөр">
                      <el-input
                        v-model="historyDetail.new.start_date"
                      /> </el-form-item
                  ></el-col>
                  <el-col :span="12">
                    <el-form-item label="Дуусах өдөр">
                      <el-input
                        v-model="historyDetail.new.end_date"
                      /> </el-form-item
                  ></el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Эхлэх цаг">
                      <el-input
                        v-model="historyDetail.new.start_time"
                      /> </el-form-item
                  ></el-col>
                  <el-col :span="12">
                    <el-form-item label="Дуусах цаг">
                      <el-input
                        v-model="historyDetail.new.end_time"
                      /> </el-form-item
                  ></el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="TPR хязгаар">
                      <el-input
                        v-model="historyDetail.new.tpr_limit"
                      /> </el-form-item
                  ></el-col>
                  <el-col :span="12">
                    <el-form-item label="7 хоног">
                      <el-select
                        multiple
                        v-model="historyDetail.new.weeking_days"
                      >
                        <el-option
                          v-for="(day, indexDay) in historyDetail.new
                            .weeking_days"
                          :key="indexDay"
                          :label="day"
                          :value="day"
                        >
                        </el-option>
                      </el-select> </el-form-item
                  ></el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item label="Тайлбар">
                      <el-input
                        type="textarea"
                        :rows="2"
                        v-model="historyDetail.new.desc"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-card> </el-row
        ></el-col>
        <el-col :span="12" v-if="historyDetail.type !== 'create'">
          <el-row :gutter="20">
            <el-card class="box-card">
              <el-form :model="historyDetail" :disabled="true">
                <el-row
                  ><span
                    ><strong>Шинэ</strong> -
                    {{ historyDetail.old.created_at }}</span
                  ><el-tag
                    size="mini"
                    type="primary"
                    class="ml10"
                    v-if="historyDetail.old.status"
                    >Идэвхтэй</el-tag
                  >
                  <el-tag size="mini" type="info" class="ml10" v-else
                    >Идэвхгүй</el-tag
                  >
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      label="Hexagon Нэр"
                      :class="
                        historyDetail.new.hexa_name !==
                        historyDetail.old.hexa_name
                          ? 'labelColor'
                          : ''
                      "
                    >
                      <el-input
                        v-model="historyDetail.old.hexa_name"
                      /> </el-form-item
                  ></el-col>
                  <el-col :span="12">
                    <el-form-item label="Үүссэн өдөр">
                      <el-input
                        v-model="historyDetail.old.created_at"
                      /> </el-form-item
                  ></el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      label="Эхлэх өдөр"
                      :class="
                        historyDetail.new.start_date !==
                        historyDetail.old.start_date
                          ? 'labelColor'
                          : ''
                      "
                    >
                      <el-input
                        v-model="historyDetail.old.start_date"
                      /> </el-form-item
                  ></el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="Дуусах өдөр"
                      :class="
                        historyDetail.new.end_date !==
                        historyDetail.old.end_date
                          ? 'labelColor'
                          : ''
                      "
                    >
                      <el-input
                        v-model="historyDetail.old.end_date"
                      /> </el-form-item
                  ></el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      label="Эхлэх цаг"
                      :class="
                        historyDetail.new.start_time !==
                        historyDetail.old.start_time
                          ? 'labelColor'
                          : ''
                      "
                    >
                      <el-input
                        v-model="historyDetail.old.start_time"
                      /> </el-form-item
                  ></el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="Дуусах цаг"
                      :class="
                        historyDetail.new.end_time !==
                        historyDetail.old.end_time
                          ? 'labelColor'
                          : ''
                      "
                    >
                      <el-input
                        v-model="historyDetail.old.end_time"
                      /> </el-form-item
                  ></el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      label="TPR хязгаар"
                      :class="
                        historyDetail.new.tpr_limit !==
                        historyDetail.old.tpr_limit
                          ? 'labelColor'
                          : ''
                      "
                    >
                      <el-input
                        v-model="historyDetail.old.tpr_limit"
                      /> </el-form-item
                  ></el-col>
                  <el-col :span="12">
                    <el-form-item label="7 хоног">
                      <el-select
                        multiple
                        v-model="historyDetail.old.weeking_days"
                      >
                        <el-option
                          size="mini"
                          v-for="(day, indexDay) in historyDetail.old
                            .weeking_days"
                          :label="day"
                          :key="indexDay"
                          :value="day"
                        ></el-option
                      ></el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item
                      label="Тайлбар"
                      :class="
                        historyDetail.new.desc !== historyDetail.old.desc
                          ? 'labelColor'
                          : ''
                      "
                    >
                      <el-input
                        type="textarea"
                        :rows="2"
                        v-model="historyDetail.old.desc"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-card> </el-row
        ></el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-button
          size="mini"
          class="mt10"
          type="danger"
          @click="visibleDialoghistory = false"
          >Хаах</el-button
        ></el-row
      >
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import { h3ToGeoBoundary, h3ToChildren } from "h3-js";
export default {
  created() {
    this.getDefaultHexagonList();
    if (this.$route.query.id) {
      this.edit = true;
      this.getOneTpr(this.$route.query.id);
    }
  },
  name: "tprConfigAdd",
  data() {
    return {
      historyDetail: null,
      visibleDialoghistory: false,
      edit: false,
      tprDataHistory: null,
      UBHexagonList: [
        "86255a38fffffff",
        "86255a217ffffff",
        "86255a39fffffff",
        "86255a2a7ffffff",
        "86255a3afffffff",
        "86255a237ffffff",
        "86255a387ffffff"
      ],
      zoomSize: 11,
      hexagonResourceSize: 8,
      tprData: {},
      optionsHexa: this.setOptionsHexa(),
      optionsDay: this.setOptionsDay(),
      center: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      },
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      position: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      },
      hexagon_zone: {
        allowed_list: [],
        blocked_list: []
      },
      hexagonTable: {
        "20": 14,
        "19": 13,
        "18": 12,
        "17": 11,
        "16": 10,
        "15": 9,
        "14": 8,
        "13": 7,
        "12": 6,
        "11": 5,
        "10": 4,
        "9": 3,
        "8": 2,
        "7": 1
      },
      rules: {
        hexa_name: [
          {
            required: true,
            message: "Та нэрээ оруулна уу",
            trigger: "blur"
          }
        ],
        start_date: [
          {
            required: true,
            message: "Та эхлэх өдөр оруулна уу",
            trigger: "blur"
          }
        ],
        end_date: [
          {
            required: true,
            message: "Та дуусах өдөр оруулна уу",
            trigger: "blur"
          }
        ],
        start_time: [
          {
            required: true,
            message: "Та эхлэх цагаа оруулна уу",
            trigger: "blur"
          }
        ],
        end_time: [
          {
            required: true,
            message: "Та дуусах цагаа оруулна уу",
            trigger: "blur"
          }
        ],
        tpr_limit: [
          {
            required: true,
            message: "Та tpr хязгаар оруулна уу",
            trigger: "blur"
          }
        ],
        desc: [
          {
            required: true,
            message: "Та тайлбараа оруулна уу",
            trigger: "blur"
          }
        ],
        weeking_days: [
          {
            required: true,
            message: "Та 7 хонгийн өдрөө сонгоно уу...",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    onClickHistory(data) {
      this.visibleDialoghistory = true;
      this.historyDetail = data;
    },
    getOneTpr(id) {
      this.openFullScreenLoader(true);
      service.getOneTpr(id).then(response => {
        if (response.data.status === "success") {
          this.tprData = response.data.data;
          this.tprDataHistory = response.data.history;
          this.openFullScreenLoader(false);
        } else {
          this.openFullScreenLoader(false);
        }
      });
    },
    setHexagonValue(name) {
      this.hexagonResourceSize = name;
    },

    getDefaultHexagonList() {
      const currentHexagonSize = parseInt(this.hexagonResourceSize);
      const hexagonList = [];
      this.UBHexagonList.forEach(el => {
        let hexagons = h3ToChildren(el, currentHexagonSize);
        hexagonList.push(...hexagons);
      });
      return this.generatePolygonsFromHexagonList(hexagonList);
    },

    generatePolygonsFromHexagonList(hexagonList) {
      hexagonList = [...new Set(hexagonList)];
      const tempData = [];
      hexagonList.forEach(el => {
        const polygon = h3ToGeoBoundary(el);
        let temp = {
          label: el,
          points: []
        };
        polygon.forEach(item => {
          temp.points.push({
            lat: item[0],
            lng: item[1]
          });
        });
        tempData.push(temp);
      });
      return tempData;
    },

    getPolygonFillOpacity(type) {
      if (type === "allowed_list") {
        return true;
      }
      return false;
    },

    updateTpr(data) {
      const body = {
        start_date: data.start_date,
        end_date: data.end_date,
        start_time: data.start_time,
        end_time: data.end_time,
        hexa_name: parseInt(data.hexa_name),
        desc: data.desc,
        tpr_limit: parseFloat(data.tpr_limit),
        weeking_days: data.weeking_days,
        status: data.status
      };
      this.$refs.tprValidate.validate(valid => {
        if (valid) {
          this.openFullScreenLoader(true);
          service.updateTpr(body, data.id).then(response => {
            if (response.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: response.data.message,
                type: "success"
              });
              this.openFullScreenLoader(false);
              this.$router.push({
                path: "/tpr-config"
              });
            } else {
              this.$notify({
                title: "Амжилтгүй",
                message: response.data.message,
                type: "warning"
              });
              this.openFullScreenLoader(false);
            }
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          this.openFullScreenLoader(false);
        }
      });
    },

    createTpr(data) {
      const body = {
        start_date: data.start_date,
        end_date: data.end_date,
        start_time: data.start_time,
        end_time: data.end_time,
        hexa_name: parseInt(data.hexa_name),
        desc: data.desc,
        tpr_limit: parseFloat(data.tpr_limit),
        weeking_days: data.weeking_days,
        status: data.status
      };
      this.$refs.tprValidate.validate(valid => {
        if (valid) {
          this.openFullScreenLoader(true);
          service.createTpr(body).then(response => {
            if (response.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "TPR амжилттай нэмэгдлээ",
                type: "success"
              });
              this.openFullScreenLoader(false);
              this.$router.push({
                path: "/tpr-config"
              });
            } else {
              this.openFullScreenLoader(false);
              this.$notify({
                title: "Амжилтгүй",
                message: response.data.message,
                type: "warning"
              });
            }
          });
        } else {
          this.openFullScreenLoader(false);
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
        }
      });
    },

    setOptionsHexa() {
      const tempData = [];
      for (let i = 5; i < 10; i++) {
        tempData.push({ label: i + 1, value: i + 1 });
      }
      return tempData;
    },

    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },

    setOptionsDay() {
      return [
        {
          value: "Mon",
          label: "Даваа"
        },
        {
          value: "Tue",
          label: "Мягмар"
        },
        {
          value: "Wed",
          label: "Лхагва"
        },
        {
          value: "Thu",
          label: "Пүрэв"
        },
        {
          value: "Fri",
          label: "Баасан"
        },
        {
          value: "Sat",
          label: "Бямба"
        },
        {
          value: "Sun",
          label: "Ням"
        }
      ];
    }
  }
};
</script>

<style>
.labelColor .el-form-item__label {
  color: red;
}
</style>
